.app {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.preview {
  flex-grow: 1;
  padding: 4rem;
  background: rgb(59, 58, 85);
  background: linear-gradient(
    135deg,
    rgba(59, 58, 85, 1) 0%,
    rgba(167, 45, 68, 1) 90%
  );
}

.chrome {
  width: 100%;
  border: 1px solid #2d3748;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  -webkit-box-shadow: 0px 9px 20px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 20px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 20px 0px rgba(0, 0, 0, 0.75);
}

.chrome-header {
  background-color: #edf2f7;
  min-height: 50px;
  color: black;
  padding: 3rem 2rem 1rem 2rem;
}

.chrome-preview {
  padding: 2rem 2rem;
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
}
