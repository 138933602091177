.siderail {
  background-color: #2d3748;
  color: white;
  width: 300px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 2rem 1rem;
  box-sizing: border-box;
}

.siderail h1 {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin: 2rem 0 4rem 0;
}

.siderail label {
  display: block;
  line-height: 1.625;
  font-size: 14px;
  font-weight: 600;
}

.siderail input[type="text"] {
  padding: 1rem 0;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid lightgray;
  color: white;
  width: 100%;
  font-size: 13.3333px;
}

.siderail input[type="text"]:focus {
  border-bottom: 1px solid white;
  outline: none;
}

.siderail .field:not(:first-child) {
  margin: 2rem 0;
}

.siderail button {
  padding: 0.5rem 1rem;
  font-weight: 700;
  border-radius: 0.25rem;
  margin: 0 auto;
  border: none;
  background-color: #4299e1;
  color: white;
}

.siderail button:hover {
  background-color: #2b6cb0;
}

.siderail a {
  color: white;
}

.siderail .instructions {
  display: block;
  border-radius: 5px;
  margin-top: 2rem;
  text-align: center;
  border: 1px dashed white;
  padding: 1rem;
  line-height: 1.5;
}

.siderail .instructions button {
  display: inline;
  border: none;
  text-decoration: underline;
  color: white;
  background-color: transparent;
  font-size: inherit;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.warning {
  background-color: #fffff0;
  color: #b7791f;
  border: 1px solid #ecc94b;
  padding: 1rem;
  border-radius: 5px;
  font-size: 14px;
}

.warning p {
  line-height: 1.5;
}